import { useState } from "react";
import emailjs from "emailjs-com";
import {
  EmailJS_Services_Id,
  EmailJS_template_Id,
  EmailJS_public_key_Id,
} from "../env.jsx";
import { useAlert } from "react-alert";

export const Contact = (props) => {
  const initialState = {
    from_name: "",
    reply_to: "",
    message: "",
  };

  const alert = useAlert();
  const [{ from_name, reply_to, message }, setState] = useState(initialState);
const [isLoading,setIsLoading]=useState(false)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    emailjs
      .sendForm(
        EmailJS_Services_Id,
        EmailJS_template_Id,
        e.target,
        EmailJS_public_key_Id
      )
      .then(
        (result) => {
          console.log(result.text);
          alert.show("Message is Sent");
          clearState();
          setIsLoading(false);
        },
        (error) => {
          console.log(error.text);
          alert.show(
            "Message is not Sent Please check your internet connection"
          );
        }
      ).catch((e)=> alert.show("Message is not Sent Please check your Internet Connection"))
  };
  return (
    <div data-aos="fade-right" data-aos-easing="ease-out-cubic"
    data-aos-duration="1000">
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="from_name"
                        className="form-control"
                        placeholder="Name"
                        required
                        value={from_name}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="reply_to"
                        className="form-control"
                        placeholder="Email"
                        required
                        value={reply_to}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    value={message}
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button disabled={isLoading===true} type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.linkedIn : "/"}>
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.github : "/"}>
                      <i className="fa fa-github"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
