import { Image } from "./image";

export const Client = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Representative Clients</h2>
         
        </div>
        <div className='row'>
          <div className='portfolio-items' >
            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} style={{display:"inline-flex",margin:70, marginTop:5}}  >
                  <Image smallImage={d.smallImage} />
                </div>
              ))
              : 'Loading...'}
          </div>
        </div>
      </div>
    </div>
  )
}
