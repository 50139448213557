export const  GalleryImages = ({ title, largeImage, smallImage,link }) => {
    return (
      <div data-aos="flip-left"
      data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
      className='portfolio-item' style={{padding:10}}>
        <div className='hover-bg'>
          {' '}
          <a
            href={link}
            title={title}
            data-lightbox-gallery='gallery1'
          >
            <div className='hover-text'>
              <h4 >{title}</h4>
            </div>
            <img
            style={{borderRadius:10}}
              src={smallImage}
              className='img-responsive'
              alt={title}
            />{' '}
          </a>{' '}
        </div>
      </div>
    )
  }