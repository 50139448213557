export const Image = ({  smallImage }) => {
  return (
    <div className="portfolio-item element">
      <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="1000" className="d-flex justify-content-center">
        <img alt="..." src={smallImage} className="img-responsive"/>
      </div>
    </div>
  );
};
