import {GalleryImages} from './GalleryImages'
export const Gallery = (props) => {
  return (
    <div id='testimonials'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>Our Gallery</h2>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  <GalleryImages title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} link={d.link}  />
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
